<form [formGroup]="allyForm">
  <div class="bl-contenedor">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 ps-5 pt-3">
          <img
            src="../../../assets/images/logo/logo-registro.png"
            alt="Logo"
            width="120"
          />
        </div>
      </div>
    </div>
    <div class="container py-5 bl-contenedor--pasos">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <!-- Paso 1: Términos y condiciones -->
          <div *ngIf="step === 1">
            <div class="bl-cardVerde">
              <img
                src="../../../assets/img/iconoTerminos.png"
                alt="Logo"
                width="20"
              />

              <div>
                <h2 class="mt-2">Términos y condiciones</h2>
                <p class="text-white">
                  Estar al tanto de tus responsabilidades es tan importante así
                  como para zíro
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="bl-cardVerde">
                  <img
                    src="../../../../assets/img/iconoHoja.png"
                    alt="Logo"
                    width="20"
                  />
                  <div>
                    <h2 class="mt-2">
                      Aviso de <br />
                      privacidad
                    </h2>
                    <p class="text-white">
                      Declaras que zíro te ha informado del uso de tus datos y
                      autorizas a que podamos etc etc
                    </p>
                    <div class="text-end">
                      <a href="auth/registro" class="text-verde">
                        <i class="fa-solid fa-chevron-right text-verde"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="bl-cardVerde">
                  <img
                    src="../../../../assets/img/iconoHoja.png"
                    alt="Logo"
                    width="20"
                  />
                  <div>
                    <h2 class="mt-2">Reglamentos</h2>
                    <br />
                    <p class="text-white">
                      Condiciones del manejo de tu cuenta zíro
                    </p>
                    <div class="text-end">
                      <a href="auth/registro" class="text-verde">
                        <i class="fa-solid fa-chevron-right text-verde"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- opciones continuar -->
            <div class="row mt-5">
              <div class="col-6">
                <div class="form-check check-terminos">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="termsCheckbox"
                    formControlName="termsCheckbox"
                  />
                 
                  <label
                    class="form-check-label text-white"
                    for="termsCheckbox"
                  >
                    Aceptar todos
                  </label>
                </div>
                <p class="text-danger" *ngIf="validateCampo(allyForm, 'termsCheckbox') as error">
                  {{ error }}
                </p>
              </div>
              <div class="col-6 text-end">
                <button class="btn btn-next" (click)="nextStep()">
                  <i class="fa-solid fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- Paso 2: Verificación de identidad FOTO FRONTAL -->
          <div *ngIf="step === 2">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i
                ></span>

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">2</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>
            <!-- indicaciones de foto -->
            <div class="row">
              <div class="col-md-12">
                <div class="bl-contentTitulo">
                  <h4 class="text-white text-center">
                    Por seguridad, verifiquemos tu identidad
                  </h4>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoDado.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Verifica que tu documento esté en una superficie plana.
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoGrafico.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Asegúrate de que tu documento este centrado en el recuadro y
                    cuente con buena iluminación
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoIdentificacion.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Recuerda usar tu documento original. No aceptamos fotocopias
                    ni otros documentos
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 justify-content-center">
              <div id="cameraOutput" class="col-12 col-md-8">
                <img
                  src="../../../../assets/img/fotoselfiefrontal.png"
                  alt=""
                  class="img-fluid mx-auto d-block"
                  *ngIf="!imagenFrontalVisible"
                />

                <div class="img-wrapper" *ngIf="imagenFrontalVisible">
                  <img [src]="documentFrontImage" class="img-fotoTomada" />
                </div>
              </div>
            </div>
            <!-- continuar -->
            <div class="row mt-5">
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="prevStep()">
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="col-8 text-center">
                <div class="file-select file-selectOtraFoto mb-3 me-2">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'frontal')"
                    #fileInput
                  />
                </div>
                <div class="file-select mb-3 me-2">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'frontal')"
                    #fileInput
                  />
                </div>
              </div>
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="nextStep()">
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 3: Verificación de identidad FOTO reverso -->
          <div *ngIf="step === 3">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i
                ></span>

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">2</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="bl-contentTitulo">
                  <h4 class="text-white text-center">
                    Por seguridad, verifiquemos tu identidad
                  </h4>
                </div>
              </div>
            </div>
            <!-- contenido -->
            <div class="row mt-3 mb-4">
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoDado.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Verifica que tu documento esté en una superficie plana.
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoGrafico.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Asegúrate de que tu documento este centrado en el recuadro y
                    cuente con buena iluminación
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoIdentificacion.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Recuerda usar tu documento original. No aceptamos fotocopias
                    ni otros documentos
                  </div>
                </div>
              </div>
            </div>
            <!-- foto reverso -->
            <div class="row mt-5 justify-content-center">
              <div class="col-12 col-md-8">
                <img
                  src="../../../../assets/img/fotoReverso.png"
                  alt=""
                  class="img-fluid mx-auto d-block"
                  *ngIf="!imagenTraseraVisible"
                />
                <div class="img-wrapper" *ngIf="imagenTraseraVisible">
                  <img [src]="documentBackImage" class="img-fotoTomada" />
                </div>
              </div>
            </div>
            <!-- continuar -->
            <div class="row mt-5">
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="prevStep()">
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="col-8 text-center">
                <div class="file-select file-selectOtraFoto mb-3 me-2">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'trasera')"
                    #fileInput
                  />
                </div>
                <div class="file-select mb-3">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'trasera')"
                    #fileInput
                  />
                </div>
              </div>
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="nextStep()">
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 3: Verificación de identidad FOTO selfie -->
          <div *ngIf="step === 4">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i
                ></span>

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">2</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="bl-contentTitulo">
                  <h4 class="text-white text-center">
                    Por seguridad, verifiquemos tu identidad
                  </h4>
                </div>
              </div>
            </div>

            <!--  foto selfie -->
            <div class="row mt-4 justify-content-evenly">
              <div class="col-12 col-md-6 col-lg-4">
                <img
                  src="../../../../assets/img/fotoSelfie.png"
                  alt=""
                  class="img-fluid mx-auto d-block"
                  *ngIf="!imagenSelfieVisible"
                />
                <div class="img-wrapper" *ngIf="imagenSelfieVisible">
                  <img [src]="selfieImage" class="img-fotoTomada" />
                </div>
              </div>

              <div class="col-md-6 col-lg-4 align-self-center">
                <div
                  class="bl-cardVerde text-center text-white bl-cardVerde--icono"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoAlerta.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Recuerda no usar <br />
                    gorras, gafas o accesorios
                  </div>
                </div>

                <div
                  class="bl-cardVerde bl-cardVerde--icono text-center text-white mt-5"
                >
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoBombillo.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    Asegúrate de buscar <br />
                    una buena iluminación
                  </div>
                </div>
              </div>
            </div>
            <!-- continuar -->
            <div class="row mt-5">
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="prevStep()">
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="col-8 text-center">
                <div class="file-select file-selectOtraFoto mb-3 me-2">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'selfie')"
                    #fileInput
                  />
                </div>

                <div class="file-select mb-3">
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'selfie')"
                    #fileInput
                  />
                </div>
              </div>
              <div class="col-2 text-center align-self-center">
                <div class="btn-fotoPrev" (click)="nextStep()">
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 4: CODIGO DEL PROVEEDOR -->
          <div *ngIf="step === 5">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i
                ></span>

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">2</span></span
                >
                <span class="text-white verdeClaro1 lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>

            <!-- INICIO BLOQUE -->

            <div class="row mt-5">
              <div class="col-12 text-white">
                <div class="bl-cardVerde text-white bl-cardVerde--icono">
                  <div class="bl-card--icono">
                    <img
                      src="../../../../assets/img/iconoAlerta.png"
                      alt="Logo"
                      width="20"
                    />
                  </div>
                  <div class="text-xs">
                    <p>Generar tu crédito con Zíro es muy fácil!</p>
                    <p>
                      Para empezar debes tener a la mano tu cédula física y un
                      celular con cámara donde puedas recibir mensajes de texto.
                      Ten en cuenta que la financiación se realizara únicamente
                      a persona natural (si eres empresa la solicitud la debe
                      realizar el Representante Legal o el dueño de la empresa).
                      El correo que registres en nuestro sistema es donde
                      recibirás toda la información de aprobación y seguimiento
                      de tu crédito. Guarda muy bien la contraseña que crees
                      para que puedas tener fácil acceso a tu cuenta de Zíro.
                      Allí podrás revisar tu estado, fecha de pago y realizar de
                      manera ágil tus pagos.
                    </p>
                    <p>
                      Cuando hayas llenado la información, el equipo de Zíro te
                      enviará un link para realizar tu verificación. Una vez se
                      realicé tu validación te estaremos confirmado la
                      aprobación de tu cupo.
                    </p>
                    <p>
                      El equipo de Zíro estará apoyándote en cualquier inquietud
                      que tengas en el proceso. ¿Qué esperas para comunicarte
                      con nosotros a través de Whatsapp? Es muy fácil, ¿No?
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <div class="form-group">
                  <label for="code_shop" class="text-white"
                    >Código de tu proveedor</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="code_shop"
                    placeholder="Digita el código de tu proveedor"
                    value="73221084"
                    formControlName="code_shop"
                    required
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'code_shop') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <!-- FIN BLOQUE -->

            <!-- continuar -->
            <div class="row justify-content-around py-5">
              <div class="col-6 text-start">
                <div class="text-center">
                  <div class="btn-fotoPrev" (click)="prevStep()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </div>

              <div class="col-6 text-end">
                <div class="text-center">
                  <div class="btn-fotoPrev me-0 ms-auto" (click)="nextStep()">
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 4: Información personal -->
          <div *ngIf="step === 6">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">1</span></span
                >

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i>
                </span>
                <span class="text-white verdeClaro1 lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name" class="text-white">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    required
                    formControlName="name"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'name') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="last_name" class="text-white">Apellidos</label>
                  <input
                    type="text"
                    class="form-control"
                    id="last_name"
                    required
                    formControlName="last_name"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'last_name') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="identification_type" class="text-white"
                    >Tipo de identificación</label
                  >
                  <select
                    class="form-control"
                    id="identification_type"
                    required
                    formControlName="identification_type"
                  >
                    <option value="">Seleccionar</option>
                    <option value="CC">Cédula de ciudadanía</option>
                    <option value="CE">Cédula de extranjería</option>
                  </select>
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'identification_type') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="identification" class="text-white"
                    >Tu número de identificación</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="identification"
                    required
                    formControlName="identification"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'identification') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <!-- continuar -->
            <div class="row justify-content-around py-5">
              <div class="col-6 text-start">
                <div class="text-center">
                  <div class="btn-fotoPrev" (click)="prevStep()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </div>

              <div class="col-6 text-end">
                <div class="text-center">
                  <div class="btn-fotoPrev me-0 ms-auto" (click)="nextStep()">
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 5: Datos de contacto -->
          <div *ngIf="step === 7">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">1</span></span
                >

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i>
                </span>
                <span class="text-white verdeClaro1 lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">3</span></span
                >
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>

            <div class="row pt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phone_1" class="text-white"
                    >Número de celular</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="phone_1"
                    formControlName="phone_1"
                    required
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'phone_1') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="phone_2" class="text-white"
                    >Número de celular 2</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="phone_2"
                    formControlName="phone_2"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'phone_2') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="address" class="text-white"
                    >Tu dirección personal</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    required
                    formControlName="address"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'address') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email" class="text-white"
                    >Tu Correo Electrónico</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    formControlName="email"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'email') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="password" class="text-white">Tu Contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    required
                    formControlName="password"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'password') as error">
                      {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <!-- continuar -->
            <div class="row justify-content-around py-5">
              <div class="col-6 text-start">
                <div class="text-center">
                  <div class="btn-fotoPrev" (click)="prevStep()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </div>

              <div class="col-6 text-end">
                <div class="text-center">
                  <div class="btn-fotoPrev me-0 ms-auto" (click)="nextStep()">
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Paso 6: Información de tu negocio -->
          <div *ngIf="step === 8">
            <div class="bl-stepFotos">
              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">1</span></span
                >

                <!-- <span class="bl-iconStep"><span class="icon-step">2</span></span> -->
                <span class="text-verdeClaro1 lh-1 d-none d-md-block">
                  Verificación <br />
                  de identidad
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep"
                  ><span class="icon-step">2</span></span
                >
                <span class="text-white verdeClaro1 lh-1 d-none d-md-block">
                  Datos <br />
                  personales
                </span>
              </div>

              <div class="bl-itemStep">
                <span class="bl-iconStep">
                  <i
                    class="fa-sharp fa-solid fa-circle-check text-verdeClaro text-128"
                  ></i
                ></span>
                <span class="text-white lh-1 d-none d-md-block">
                  Datos de <br />
                  tu negocio
                </span>
              </div>
            </div>

            <!-- inicio formulario -->
            <div class="row pt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nit" class="text-white"
                    >Cédula o NIT de tu negocio</label
                  >
                  <input
                    type="text"
                    class="form-control mt-0"
                    id="nit"
                    required
                    formControlName="nit"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'nit') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="value_credit" class="text-white"
                    >¿Cuánto son tus compras al mes?</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="value_credit"
                    required
                    formControlName="value_credit"
                    (input)="formatInputToCurrency($event)"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'value_credit') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="user_id_commerce" class="text-white"
                    >Digita el user_id de tu cuenta que aparece en el comercio
                    que vas a comprar</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="user_id_commerce"
                    formControlName="user_id_commerce"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'user_id_commerce') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="business_name" class="text-white"
                    >Nombre de tu Empresa</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="business_name"
                    required
                    formControlName="business_name"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'business_name') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 select-registro">
                <div class="form-group">
                  <label for="city_id" class="text-white"
                    >Ingresa la Ciudad</label
                  >

                  <ng-select
                    class="custom-select"
                    formControlName="city_id"
                    [items]="cities"
                    bindLabel="name"
                    bindValue="id"
                    [searchable]="true"
                    [clearable]="false"
                    placeholder=""
                    required
                  >
                  </ng-select>
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'city_id') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="businness_address" class="text-white"
                    >Ingresa la dirección</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="businness_address"
                    required
                    formControlName="businness_address"
                  />
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'businness_address') as error">
                    {{ error }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="registered_chamber_commerce" class="text-white"
                    >¿Estás registrado en la cámara de comercio?</label
                  >
                  <select
                    class="form-control"
                    id="registered_chamber_commerce"
                    required
                    formControlName="registered_chamber_commerce"
                  >
                    <option value="">Seleccionar</option>
                    <option value="1">Sí</option>
                    <option value="2">No</option>
                  </select>
                  <p class="text-danger" *ngIf="validateCampo(allyForm, 'registered_chamber_commerce') as error">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
            <!-- fin formulario  -->

            <!-- botones Continuar  -->
            <div class="row justify-content-around py-5">
              <div class="col-6 text-start">
                <div class="text-center">
                  <div class="btn-fotoPrev" (click)="prevStep()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </div>

              <div class="col-6 text-end">
                <button class="btn btn-next" (click)="register()">
                  Registrarme
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="step === 9">
            <!-- botones Continuar  -->
            <div class="row justify-content-around py-5">
              <div class="col-6 text-start">
                <div class="text-center">
                  <div class="btn-fotoPrev" (click)="prevStep()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
