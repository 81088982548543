import { Component } from '@angular/core';

@Component({
  selector: 'app-commerce-dashboard-home',
  templateUrl: './commerce-dashboard-home.component.html',
  styleUrls: ['./commerce-dashboard-home.component.scss']
})
export class CommerceDashboardHomeComponent {

}
