import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/login/services/auth.service';

export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}
@Injectable({
	providedIn: "root",
})
export class NavService {
	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
		window.innerWidth
	);

	// Search Box
	public search: boolean = false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	public itemsAdmin = new BehaviorSubject<Menu[]>([]);
	public itemsCustomer = new BehaviorSubject<Menu[]>([]);
	public itemsCommerce = new BehaviorSubject<Menu[]>([]);
	public itemsAnalyst = new BehaviorSubject<Menu[]>([]);
	public items: BehaviorSubject<Menu[]>; // Variable para almacenar el BehaviorSubject actual
	//obtener rol del usuario
	public user = this.authService.getUser();
	public role = this.user ? this.user.role_id : null;
	//constructor
	constructor(private router: Router, private authService: AuthService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, "resize")
			.pipe(debounceTime(1000), takeUntil(this.unsubscriber))
			.subscribe((evt: any) => {
				this.setScreenWidth(evt.target.innerWidth);
				if (evt.target.innerWidth < 991) {
					this.collapseSidebar = true;
				}
				if (evt.target.innerWidth < 1199) {
				}
			});
		if (window.innerWidth < 991) {
			// Detect Route change sidebar close
			this.router.events.subscribe((event) => {
				this.collapseSidebar = true;
			});
		}

		// Inicializar this.items como un BehaviorSubject vacío
		this.items = new BehaviorSubject<Menu[]>([]);

		// Asignar el menú correspondiente según el rol
		switch (this.role) {
			case 1:
				this.items = this.itemsAdmin;
				break;
			case 3:
				this.items = this.itemsAnalyst;
				break;
			case 5:
				this.items = this.itemsCustomer;
				break;
			case 4 || 6:
				this.items = this.itemsCommerce;
				break;
			default:
				this.items = new BehaviorSubject<Menu[]>([]); // Se establece a BehaviorSubject vacío cuando role es null
				break;
		}
		// Asignar el valor inicial del BehaviorSubject items
		this.items.next(this.getMenuItems());
	}

	private getMenuItems(): Menu[] {
		// Devolver el menú correspondiente según el rol
		switch (this.role) {
			case 1:
				return this.MENUITEMSADMIN;
			case 3:
				return this.MENUITEMSANALYST;
			case 5:
				return this.MENUITEMSCUSTOMER;
			case 4 || 6:
				return this.MENUITEMSCOMMERCE;
			default:
				return [];
		}
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	public updateMenu(role: number): void {
		switch (role) {
			case 1:
				this.items.next(this.MENUITEMSADMIN);
				break;
			case 3:
				this.items.next(this.MENUITEMSANALYST);
				break;
			case 5:
				this.items.next(this.MENUITEMSCUSTOMER);
				break;
			case 4 || 6:
				this.items.next(this.MENUITEMSCOMMERCE);
				break;
			default:
				this.items.next([]);
				break;
		}
	}

	//Menu admin
	MENUITEMSADMIN: Menu[] = [
		{
			title: "Inicio",
			icon: "home",
			type: "link",
			active: true,
			path: "/panel/inicio",
		},
		{
			title: "Clientes",
			icon: "users",
			type: "link",
			active: false,
			path: "/clientes/listado",
		},
		{
			title: "Créditos",
			icon: "tables",
			type: "link",
			active: false,
			path: "/creditos/listado",
		},
		{
			title: "Códigos enviados",
			icon: "users",
			type: "link",
			active: false,
			path: "/codigos-otp/listado",
		},
		{
			title: "Empleados",
			icon: "users",
			type: "link",
			active: false,
			path: "/empleados/listado",
		},
		{
			title: "Gestión Cobranza",
			icon: "widgets",
			type: "link",
			active: false,
			path: "/cobranza/listado",
		},
		{
			title: "Informes",
			icon: "project",
			type: "sub",
			active: false,
			children: [
				{
					title: "Cartera",
					path: "/reportes/cartera",
					type: "link",
				},
				{ 
                    title: "Cupos", 
                    path: "/reportes/cupos", 
                    type: "link" 
                },
			],
		},
		{
			title: "Lineas de crédito",
			icon: "ecommerce",
			type: "link",
			active: false,
			path: "/lineas-credito/listado",
		},
		{
			title: "Pagos proveedores",
			icon: "uikits",
			type: "link",
			active: false,
			path: "/proveedores-pagos/listado",
		},
		{
			title: "Parametros",
			icon: "other",
			type: "link",
			active: false,
			path: "/parametros",
		},
		{
			title: "Proveedores Aliados",
			icon: "users",
			type: "link",
			active: false,
			path: "/proveedores-aliados/listado",
		},
		{
			title: "Solicitudes de cupo",
			icon: "tables",
			type: "sub",
			active: false,
			children: [
				{
					title: "Por Lista",
					path: "/solicitudes-credito/listado",
					type: "link",
				},
				{
					title: "Por Tarjeta",
					path: "/solicitudes-credito/tarjeta",
					type: "link",
				},
			],
		},
		{
			title: "Sucursales",
			icon: "users",
			type: "link",
			active: false,
			path: "/sucursales/listado",
		},
		{
			title: "Usuarios",
			icon: "users",
			type: "link",
			active: false,
			path: "/usuarios/listado",
		},
	];

	//menu cliente
	MENUITEMSCUSTOMER: Menu[] = [
		{
			title: "Inicio",
			icon: "home",
			type: "link",
			active: true,
			path: "/clientes/inicio",
		},
		{
			title: "Solicitudes de cupo",
			icon: "users",
			type: "link",
			active: false,
			path: "/clientes/solicitudes-credito",
		},
		{
			title: "Créditos",
			icon: "users",
			type: "link",
			active: false,
			path: "/clientes/creditos",
		},
		{
			title: "Pagos",
			icon: "users",
			type: "link",
			active: false,
			path: "/clientes/pagos",
		},
	];

	//menu analista
	MENUITEMSANALYST: Menu[] = [
		{
			title: "Inicio",
			icon: "home",
			type: "link",
			active: true,
			path: "/panel/inicio",
		},
		{
			title: "Solicitudes de cupo",
			icon: "tables",
			type: "sub",
			active: false,
			children: [
				{
					title: "Por Lista",
					path: "/solicitudes-credito/listado",
					type: "link",
				},
				{
					title: "Por Tarjeta",
					path: "/solicitudes-credito/tarjeta",
					type: "link",
				},
			],
		},
	];

	//menu proveedores -sucursales
	MENUITEMSCOMMERCE: Menu[] = [
		{
			title: "Inicio",
			icon: "home",
			type: "link",
			active: true,
			path: "/proveedores/panel/inicio",
		},
		{
			title: "Solicitudes de crédito",
			icon: "home",
			type: "link",
			active: false,
			path: "/proveedores/solicitudes/creditos/listado",
		},
		{
			title: "Sucursales",
			icon: "home",
			type: "link",
			active: false,
			path: "/sucursales/listado",
		},
		{
			title: "Créditos",
			icon: "home",
			type: "link",
			active: false,
			path: "/creditos/listado",
		},
        {
			title: "Informes",
			icon: "home",
			type: "link",
			active: false,
			path: "/proveedores/reporte/listado",
		},
	];
}
