import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { AdminGuard } from "./shared/guard/admin.guard";
import { full } from "./shared/routes/full";
import { administrativeRouters } from "./shared/routes/administrative-routers";
import { commerceRouters } from "./shared/routes/commerce-routers";
import { customerRouters } from "./shared/routes/customer-routers";
import { ErrorPage1Component } from "./pages/error-page/error-page1/error-page1.component";
import { LoginComponent } from "./auth/login/login.component";
import { RegisterComponent } from "./auth/Register/register.component";

const routes: Routes = [
    {
        path: "auth/login",
        component: LoginComponent,
    },
    {
        path: "auth/registro",
        component: RegisterComponent,
    },
    {
        path: "error/404",
        component: ErrorPage1Component,
    },
    {
        path: "",
        component: ContentComponent,
        canActivate: [AdminGuard],
        children: administrativeRouters,
    },
    {
        path: "",
        component: ContentComponent,
        children: commerceRouters,
    },
    {
        path: "",
        component: ContentComponent,
        children: customerRouters,
    },
    {
        path: "",
        component: FullComponent,
        children: full,
    },
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: "**",
        redirectTo: "/error/404",
    },
];


@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: "enabled",
			scrollPositionRestoration: "enabled",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
