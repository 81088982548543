import { Component } from '@angular/core';
import { AdministrativeDashboardService } from '../../services/administrative-dashboard.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { formatCurrency, toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-administrative-dashboard-home',
  templateUrl: './administrative-dashboard-home.component.html',
  styleUrls: ['./administrative-dashboard-home.component.scss']

})
export class AdministrativeDashboardHomeComponent {

    withdrawnCredits:   number;
    overdueCredits:     number;
    totalCustomers:    number;
    totalSuppliers:    number;
    formatCurrency = formatCurrency;

    constructor(
        private administrativeDashboardService: AdministrativeDashboardService,
        private loaderService: LoaderService,
      ) {}

    ngOnInit(): void {
        toggleLoader(this.loaderService, true);
        this.getDataDashboard();
      }
    
      //getDataDashboard
      getDataDashboard() {
        this.administrativeDashboardService.getDataDashboard().subscribe(
          (response) => {
            if (response.ok) {
              toggleLoader(this.loaderService, false);
              // Manejar la respuesta del servicio
              this.withdrawnCredits = response.data.withdrawnCredits;
              this.overdueCredits = response.data.overdueCredits;
              this.totalCustomers = response.data.totalCustomers;
              this.totalSuppliers = response.data.totalSuppliers;
              console.log(response);
            } else {
              toggleLoader(this.loaderService, false);
              Swal.fire(
                'Error',
                'Ocurrió un error al consultar la información del cliente.',
                'error'
              );
            }
          },
          (error) => {
            toggleLoader(this.loaderService, false);
            // Manejar el error del servicio
            Swal.fire(
              'Error',
              'Ocurrió un error al consultar la información del cliente.',
              'error'
            );
            console.error(error);
          }
        );
    }
}
