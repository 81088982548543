import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseSearch } from '../interfaces/register.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  /*************
   *  variables**
   *************/
  private baseUrl: string = environment.apiUrl + '/clientes';
  private baseUsuario: string = environment.apiUrl + '/usuarios';
  private baseUrlSucursal: string = environment.apiUrl + '/sucursales';
  private _refreshUsers$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  /*************
   *  getters****
   *************/
  get _refreshUsers() {
    return this._refreshUsers$;
  }

  /*************
   *  metodos****
   *************/

  verifyCedula(cedula: string) {
    const url = `${this.baseUrl}/existe/cedula/${cedula}`;
    return this.http.get<ResponseSearch>(url);
  }
  verifyCodeProvider(codigo: string) {
    const url = `${this.baseUrlSucursal}/existe/codigo/${codigo}`;
    return this.http.get<ResponseSearch>(url);
  }
  verifyEmail(email: string) {
    const url = `${this.baseUsuario}/existe/correo/${email}`;
    return this.http.get<ResponseSearch>(url);
  }

  saveRegister(data: any) {
    const url = `${this.baseUrl}`;
    return this.http.post(url, data);
  }
}
