<ng-template #modalCustomersSearch let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            BUSCAR CLIENTE
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 mb-3">
                <label for="customerIdentification">Cédula del cliente</label>
                <div class="input-group">
                    <input class="form-control" [(ngModel)]="customerIdentification" />
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" (click)="searchCustomer()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="flagSearchCustomer && !customer" class="w-100">
                <h3 class="text-center text-info">
                    El cliente aún no está en el sistema
                </h3>
            </div>

            <div *ngIf="customer">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h4>Información del Cliente:</h4>
                    </div>
                </div>

                <!-- datos del cliente -->
                <div class="row mb-2">
                    <div class="col-8">
                        <strong class="flex-shrink-0 mr-3" style="font-size: 15px;">Nombre del Cliente:</strong>
                    </div>
                    <div class="col-4">
                        <span class="flex-grow-1 pl-3" style="font-size: 15px;">{{ customer.name }} - {{ customer.id }}</span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-8">
                        <strong class="flex-shrink-0 mr-3" style="font-size: 15px;">Solicitudes en Proceso:</strong>
                    </div>
                    <div class="col-4">
                        <span class="flex-grow-1 pl-3" style="font-size: 15px;">{{ totalProcessPending}} </span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-8">
                        <strong class="flex-shrink-0 mr-3" style="font-size: 15px;">Solicitudes Rechazadas:</strong>
                    </div>
                    <div class="col-4">
                        <span class="flex-grow-1 pl-3" style="font-size: 15px;">{{ totalReject}} </span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-8">
                        <strong class="flex-shrink-0 mr-3" style="font-size: 15px;">Créditos Activos:</strong>
                    </div>
                    <div class="col-4">
                        <span class="flex-grow-1 pl-3" style="font-size: 15px;">{{ totalActiveCredits}} </span>
                    </div>
                </div>
                        
                <!-- total cupo aprobado -->
                <div class="row">
                    <div class="col-12 mb-3 mt-3  text-center">
                        <h2>
                            Total cupo aprobado ${{ totalCupoAprobado | number }}
                        </h2>
                    </div>
                    <div class="col-12 mb-4  text-center">
                        <h2>
                            Total cupo disponible para compras ${{ totalCupoDisponibleCompras | number }}
                        </h2>
                        <h6 *ngIf="mora" class="font-weight-bold text-danger">Cupo bloqueado por mora</h6>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-7">
                        <label>Seleccione una sucursal</label>
                        <select class="form-control" id="shopCommerce" [(ngModel)]="shopCommerceId">
                            <option *ngFor="let shopCommerce of shopsCommerces" [value]="shopCommerce.id">
                                {{ shopCommerce.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-primary mt-2" (click)="confirmCreditWithdrawal()">
                            <i class="fas fa-coins"></i> Solicitar retiro de crédito
                        </button>
                    </div>
                </div>

                <!-- pago de cuotas -->
                <hr>
                <div class="row">
                    <h5>
                        Pago de cuotas de créditos vigentes
                    </h5>
                </div>
                <div class="row" *ngIf="creditsHistorial && creditsHistorial.length > 0">
                    <table class="table table-sm table-border">
                        <thead>
                            <th># Obligación</th>
                            <th>Valor</th>
                            <th>Proveedor</th>
                            <th>Fecha inicio</th>
                            <th>Fecha pago</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let credit of creditsHistorial">
                                <td>{{ credit.code_pay }}</td>
                                <td>{{ credit.value_credit }}</td>
                                <td>{{ credit.shop_commerce.name }}</td>
                                <td>{{ credit.delivery_date }}</td>
                                <td>{{ credit.deadline }}</td>
                                <th>
                                    <button 
                                        (click)="redirectToPaymentDetail(credit.id)"
                                        type="button" 
                                        class="btn btn-info btn-xs me-1"
                                        placement="top" 
                                        ngbTooltip="Ver detalle crédito">
                                        <i class="fas fa-money-bill"></i> 
                                    </button>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" *ngIf="creditsHistorial.length ==0">
                    <div>
                        <em class="text-danger fw-bold">El cliente no tiene créditos pendientes por pagar.</em>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancelar')">
            <i class="fa fa-times"></i> Cerrar
        </button>
    </div>
</ng-template>

<!-- modal retiro credito -->
<app-withdraw-credit />