import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreditPaginatedInterface, CreditResponseInterface, QuoteInterface, SendCodeOtpResponseInterface } from '../interfaces/credits-interface';
import { Observable, Subject, tap } from 'rxjs';
import { CreditPaymentDetailResponseInterface, CreditsPlanFormPayInterface } from '../interfaces/credits-payments-detal';

@Injectable({
  providedIn: 'root'
})
export class CreditsService {

    private baseUrl: string =environment.apiUrl+'/creditos';
    private _refreshCredits$ = new Subject<void>();


    //constructor
    constructor(private http: HttpClient) { }
  

    /*************
    *  getters****
    *************/
    get _refreshCredits() {
      return this._refreshCredits$;
    }

 
    /*************
    *  metodos****
    *************/
    getCredits() {
        return this.http.get<CreditPaginatedInterface>(this.baseUrl);
    }

    //obtener registros
    getQuoteValue(numberCuotesCredit: number, valueCredit: number, creditLine: number) {
        const url = `${this.baseUrl}/calcular-valor-cuota`;
        let params = new HttpParams();
        params = params.append('numberCuotesCredit', numberCuotesCredit.toString());
        params = params.append('valueCredit', valueCredit.toString());
        params = params.append('creditLine', creditLine.toString());
        return this.http.get<QuoteInterface>(url, { params });
    }
    
    //obtener registros
    sendCodeOtp(
        numberCuotesCredit: number, 
        valueCredit: number,
        valueCuote:number, 
        customerId:number, 
        shopCommerceId:number, 
        creditLine: number) {
        //url
        const url = `${this.baseUrl}/enviar-codigo-opt`;
        //params
        let params = new HttpParams();
        params = params.append('numberCuotesCredit', numberCuotesCredit.toString());
        params = params.append('valueCredit', valueCredit.toString());
        params = params.append('valueCuote', valueCuote.toString());
        params = params.append('customerId', customerId.toString());
        params = params.append('shopCommerceId', shopCommerceId.toString());
        params = params.append('creditLine', creditLine.toString());
        //servicio
        return this.http.get<SendCodeOtpResponseInterface>(url, { params });
    }


    //validar codigo otp
    validateCodeOtp(code: number, creditId: number){
        const url = `${this.baseUrl}/validar-codigo-opt`;
        return this.http.post<SendCodeOtpResponseInterface>(url, { code, creditId });
    }

    //aplicar credito
    applyCredit(creditId: number){
        const url = `${this.baseUrl}`;
        return this.http.post<SendCodeOtpResponseInterface>(url, {  creditId }).pipe(
            tap(() => {
              this._refreshCredits.next();
            })
        );
    }

    updateCredit(creditId: number,data:any){
        const url = `${this.baseUrl}/${creditId}`;
        alert(JSON.stringify(data));
        return this.http.put<CreditResponseInterface>(url,data).pipe(
            tap(() => {
              this._refreshCredits.next();
            })
        );
    }


    getDetailPaymentCredit(creditId: number) {
        const url = `${this.baseUrl}/detalle-pago/${creditId}`;
        return this.http.get<CreditPaymentDetailResponseInterface>(url);
    }   
    
    storeCreditPaymentByQuotes(
        creditId: number, 
        selectedPlans: CreditsPlanFormPayInterface[], 
        payValue: number) {

        const url = `${this.baseUrl}/pago-cuotas/plan`;
        const body = {
          creditId: creditId,
          selectedPlans: selectedPlans,
          creditTotalValueToPay: payValue
        };
        return this.http.post<CreditResponseInterface>(url, body);
    }

    storeCreditPaymentByValue(
        creditId: number, 
        payValue: number) {

        const url = `${this.baseUrl}/pago-cuotas/valor`;
        const body = {
          creditId: creditId,
          creditTotalValueToPay: payValue
        };
        return this.http.post<CreditResponseInterface>(url, body);
    }

    
    searchCredits(formData:any) {
        const params = new HttpParams({ fromObject: formData });
        const url =  `${this.baseUrl}/buscar`;
        return this.http.get<CreditPaginatedInterface>(url, { params });
    }
    
    searchCreditsForExport(formData:any) {
        const params = new HttpParams({ fromObject: formData });
        const url =  `${this.baseUrl}/buscar`;
        return this.http.get<CreditResponseInterface>(url, { params });
    }
}
