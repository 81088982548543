import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { SharedModule } from './shared/shared.module';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { ToastrModule } from 'ngx-toastr';
import { RegisterComponent } from './auth/Register/register.component';
import { TokenInterceptor } from './interceptors/TokenInterceptor';
import { AdministrativeDashboardHomeComponent } from './administrative/administrative-dashboards/pages/administrative-dashboard-home/administrative-dashboard-home.component';
import { CommerceDashboardHomeComponent } from './commerce/commerce-dashboards/pages/commerce-dashboard-home/commerce-dashboard-home.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LoginComponent, RegisterComponent, AdministrativeDashboardHomeComponent, CommerceDashboardHomeComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
    OverlayModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    BrowserAnimationsModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LightboxModule,
    ToastrModule.forRoot(),
    // NgxDaterangepickerBootstrapModule.forRoot()
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }
    // NgxDaterangepickerBootstrapDirective
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}