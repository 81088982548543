import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserInterface, UserResponseInterface } from "src/app/administrative/users/interfaces/users.interface";
import { environment } from "src/environments/environment";
import { AuthUserInterface, AuthUserResponseInterface } from "../interfaces/auth-user.interface";
import { Observable, of, throwError } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	//variables
	private user: AuthUserInterface;
    private baseUrl = environment.apiUrl+'/auth';
    private token: string;

    // constructor
	constructor(private http: HttpClient) {}

    
    //getter y setters
	setUser(user: AuthUserInterface): void {
		this.user = user;
		localStorage.setItem("user", JSON.stringify(user));
	}

	getUser(): any {
		if (this.user) {
			return this.user;
		}
		const user = localStorage.getItem("user");
		return user ? JSON.parse(user) : null;
	}

   setToken(token: string): void {
		this.token = token;
		localStorage.setItem("token",token);
	}

	getToken(): any {
		if (this.token) {
			return this.token;
		}
		const token = localStorage.getItem("token");
		return token ? token : null;
	}

    //funciones
    login(email: string, password: string) {
		return this.http.post<AuthUserResponseInterface>(this.baseUrl, { email, password });
	}

	logout() {
        const url= this.baseUrl+'/cerrar-sesion';
        return this.http.get<AuthUserResponseInterface>(url);
		// localStorage.removeItem("user");
        // localStorage.removeItem('token');
	}
}
