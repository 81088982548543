<div>
  <form [formGroup]="formEditCredit">
    <div class="modal-header">
      <h4 class="modal-title">
        <span>
          {{ creditState === 2 ? 'Cambiar crédito a estado entregado' : 'Editar valores de crédito' }}
        </span>
    
        <br/>
        # Obligación:
        <span class="fw-bold"> #{{ creditRequestnObligacion }}</span> <br />
        <span class="fw-bold"># {{  creditId }}</span> <br />
        <!-- valor: -->
        <!-- <span class="fw-bold">
          ${{ creditRequestValue | currency : '$' : true : '1.0-0' }}</span
        > -->
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mb-3">
          <div class="form-group">
            <label>Valor del crédito</label>
            <input
              type="text"
              class="form-control"
              formControlName="value_credit"
            />
          </div>
          <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'value_credit') as error">
            {{ error }}
          </p>
        </div>
        <div class="col-12 mb-3">
          <div class="form-group">
            <label>Fecha de inicio</label>
            <input
              class="form-control"
              type="date"
              formControlName="date_credit"
            />
          </div>
          <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'date_credit') as error">
            {{ error }}
          </p>
        </div>
    
        <div class="col-12 mb-3" *ngIf="creditState!=2">
          <div class="form-group">
            <label>Razón de la modificación</label>
            <textarea
              class="form-control"
              placeholder="Razón de la modificación"
              rows="3"
              cols="30"
              formControlName="razon_edit"
            ></textarea>
          </div>
          <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'reason_edit') as error">
            {{ error }}
          </p>
    
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-primary"
            (click)="updateCredit()"
          >
            <i class="fas fa-save"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

