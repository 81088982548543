<div class="input-group">
   <button class="btn btn-success"
      (click)="openModalCustomerSearch()">
      <i class="fa fa-search"></i> 
      Buscar cliente
   </button>
</div>

<!-- modal buscar cliente -->
<app-customers-search/>
