import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, of, throwError } from 'rxjs';
import {
  numericValidator,
  toggleLoader,
} from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';
import { ResponseSearch } from './interfaces/register.interfaces';
import { RegisterService } from './services/register.service';

import { Router } from '@angular/router';
import { NavService } from 'src/app/shared/services/nav.service';
import { ParemetersService } from 'src/app/shared/services/parameters.service';
import { validateCampo } from '../../shared/helpers/functions-helper.service';
import { AuthService } from '../login/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  allyForm: FormGroup;
  step: number = 1;
  documentFrontImage: SafeResourceUrl | undefined;
  documentBackImage: SafeResourceUrl | undefined;
  selfieImage: SafeResourceUrl | undefined;
  defaultFormControl = new FormControl();
  cities: any[] = [];
  imagenFrontalVisible: boolean = false;
  imagenTraseraVisible: boolean = false;
  imagenSelfieVisible: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  validateCampo = validateCampo;

  formControls: string[] = [
    'termsCheckbox',
    'code_shop',
    'phone_1',
    'phone_2',
    'email',
    'password',
    'address',
    'nit',
    'value_credit',
    'business_name',
    'city_id',
    'businness_address',
    'registered_chamber_commerce',
    // Agrega el resto de los nombres de los controles aquí
  ];

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private registerService: RegisterService,
    private loaderService: LoaderService,
    private parametersService: ParemetersService,
    private router: Router,
    private authService: AuthService,
    private navService: NavService
  ) {
    this.allyForm = this.formBuilder.group({
      termsCheckbox: ['', Validators.required],
      code_shop: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      identification_type: ['', Validators.required],
      identification: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      phone_1: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      // se elimina temporalmente
      // phone_2: [
      //   '',
      //   [
      //       phone2Validator,
      //   ],
      // ],
      phone_2: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      nit: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      value_credit: ['', [Validators.required, Validators.minLength(4)]],
      user_id_commerce: [''],
      business_name: ['', [Validators.required, Validators.minLength(3)]],
      city_id: ['', Validators.required],
      businness_address: ['', [Validators.required, Validators.minLength(5)]],
      registered_chamber_commerce: ['', Validators.required],
      photo_document_front: ['', Validators.required],
      photo_document_back: ['', Validators.required],
      photo_selfie: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCities();
  }

  // formatea el input a moneda
  formatInputToCurrency(event: any) {
    let inputValue = event.target.value;
    // Remove all non-numeric characters
    let numericValue = inputValue.replace(/[^0-9]/g, '');
    // Format the number with commas as thousand separators
    let formattedValue = this.formatNumberWithCommas(numericValue);
    this.allyForm.controls['value_credit'].setValue(formattedValue, {
      emitEvent: false,
    });
  }

  // Function to format a number with commas
  formatNumberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  onFileSelected(event: any, tipoFoto: string) {
    const file = event.target.files[0];
    // Ahora puedes hacer algo con el archivo, como convertirlo en una URL de objeto y mostrarlo en la página
    if (event.target && event.target.files && event.target.files.length > 0) {
      const fileUrl = URL.createObjectURL(file);
      // ...Haz algo con fileUrl, como mostrarlo en la página
      console.log(fileUrl);
      // Crear el elemento de imagen y asignarle las propiedades
      let imageElement = document.createElement('img');
      imageElement.src = fileUrl;
      imageElement.style.width = 'auto'; // ajusta a la anchura deseada
      imageElement.style.height = '300px'; // ajusta a la altura deseada

      // ...Haz algo con imageElement, como agregarlo a la página

      if (tipoFoto == 'frontal') {
        this.imagenFrontalVisible = true;
        this.documentFrontImage = fileUrl;
        this.selectedFiles['photo_document_front'] = file;
        const formControl = this.allyForm.get('photo_document_front');
        if (formControl) {
          formControl.setValue(file);
          //this.formData.append('photo_document_front', file, file.name);
        } else {
          console.error('Form control photo_document_front not found');
        }
      }
      if (tipoFoto == 'trasera') {
        this.imagenTraseraVisible = true;
        this.documentBackImage = fileUrl;
        this.selectedFiles['photo_document_back'] = file;
        const formControlBack = this.allyForm.get('photo_document_back');
        if (formControlBack) {
          formControlBack.setValue(file);
          //this.formData.append('photo_document_back', file, file.name);
        } else {
          console.error('Form control photo_document_back not found');
        }
      }
      if (tipoFoto == 'selfie') {
        this.imagenSelfieVisible = true;
        this.selfieImage = fileUrl;
        this.selectedFiles['photo_selfie'] = file;
        const formControlSelfie = this.allyForm.get('photo_selfie');
        if (formControlSelfie) {
          formControlSelfie.setValue(file);
          //this.formData.append('photo_selfie', file, file.name);
        } else {
          console.error('Form control photo_selfie not found');
        }
      }
    }
  }

  nextStep() {
    console.log(this.allyForm.value);
    console.log(this.step);
    const termsCheckbox = this.allyForm.controls['termsCheckbox'].value;
    const photo_document_front = this.allyForm.controls['photo_document_front'];
    const photo_document_back = this.allyForm.controls['photo_document_back'];
    const photo_selfie = this.allyForm.controls['photo_selfie'];

    // step 5 es codigo de proveedor
    if (this.step == 1) {
      if (!termsCheckbox) {
        this.markFieldsSelectedAsTouched(['termsCheckbox']);
        Swal.fire('Error', 'Debe aceptar los términos y condiciones.', 'error');
        return;
      }
    }
    if (this.step === 2 && photo_document_front.invalid) {
      Swal.fire(
        'Error',
        'Debe cargar la foto frontal del documento de identidad.',
        'error'
      );
      return;
    }
    if (this.step === 3 && photo_document_back.invalid) {
      Swal.fire(
        'Error',
        'Debe cargar la foto del reverso del documento de identidad.',
        'error'
      );
      return;
    }
    if (this.step === 4 && photo_selfie.invalid) {
      Swal.fire('Error', 'Debe cargar la foto selfie.', 'error');
      return;
    }
    if (this.step == 5) {
      toggleLoader(this.loaderService, true); // Mostrar el componente de carga
      this.verifyCodigoProveedorExist();

      // step 6 es cedula
    } else if (this.step == 6) {
      toggleLoader(this.loaderService, true); // Mostrar el componente de carga
      this.verifyCedulaExist();

      // step 7 es correo del cliente
    } else if (this.step == 7) {
      toggleLoader(this.loaderService, true); // Mostrar el componente de carga
      this.verifyEmailExist();
    } else if (this.step < 8) {
      this.step++;
    }
  }
  prevStep() {
    console.log(this.step);
    if (this.step == 1) {
      return;
    }
    this.step--;
  }
  getCities(): void {
    this.parametersService.getCities().subscribe((data: any) => {
      this.cities = data.data;
    });
  }

  verifyCedulaExist(): void {
    const cedula = this.allyForm.controls['identification'];
    const name = this.allyForm.controls['name'];
    const last_name = this.allyForm.controls['last_name'];
    const identification_type = this.allyForm.controls['identification_type'];
    const identification = this.allyForm.controls['identification'];
    console.log(identification_type);
    if (
      cedula.invalid ||
      name.invalid ||
      last_name.invalid ||
      identification_type.invalid ||
      identification.invalid
    ) {
      this.markFieldsSelectedAsTouched([
        'cedula',
        'name',
        'last_name',
        'identification_type',
        'identification',
      ]);
      Swal.fire('Error', 'Verifica la información ingresada', 'error');
      toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      return;
    }

    this.registerService
      .verifyCedula(cedula.value)
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            console.log('Cédula no encontrada');
            this.step++;
          } else {
            console.error('Error en la consulta de cédula:', error);
          }

          toggleLoader(this.loaderService, false); // Mostrar el componente de carga
          // Puedes retornar un valor predeterminado o un observable vacío si lo deseas
          return of(null);
        })
      )
      .subscribe((response: ResponseSearch | null) => {
        if (response && response.data) {
          console.log(response.data, 'resultado encontrado');
          // Aquí puedes manejar la respuesta cuando se encuentra la cédula
          Swal.fire(
            'Error',
            'El número de documento ' +
              cedula.value +
              ' ya se encuentra registrado',
            'error'
          );
        }

        toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      });
  }

  verifyCodigoProveedorExist(): void {
    const code_shop = this.allyForm.controls['code_shop'];
    // const code_shop = '73221084';
    console.log(code_shop);

    if (code_shop.invalid) {
      this.markFieldsSelectedAsTouched(['code_shop']);
      Swal.fire(
        'Error',
        'El código del proveedor no puede estar vacío.',
        'error'
      );
      toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      return;
    }
    this.registerService
      .verifyCodeProvider(code_shop.value)
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            console.log('Cédula no encontrada');
            Swal.fire(
              'Error',
              'El código del proveedor ' + code_shop.value + ' no existe.',
              'error'
            );
          } else {
            console.error('Error en la consulta de cédula:', error);
            Swal.fire(
              'Error',
              'Ha ocurrido un error al consultar el código del proveedor ' +
                code_shop.value,
              'error'
            );
          }

          toggleLoader(this.loaderService, false); // Mostrar el componente de carga
          // Puedes retornar un valor predeterminado o un observable vacío si lo deseas
          return of(null);
        })
      )
      .subscribe((response: ResponseSearch | null) => {
        if (response && response.data) {
          console.log(response.data, 'resultado encontrado');
          // Aquí puedes manejar la respuesta cuando se encuentra la cédula
          this.step++;
        }

        toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      });
  }

  verifyEmailExist(): void {
    const phone_1 = this.allyForm.controls['phone_1'];
    const phone_2 = this.allyForm.controls['phone_2'];
    const email = this.allyForm.controls['email'];
    const password = this.allyForm.controls['password'];
    const address = this.allyForm.controls['address'];
    if (
      email.invalid ||
      password.invalid ||
      address.invalid ||
      phone_1.invalid ||
      phone_2.invalid
    ) {
      this.markFieldsSelectedAsTouched([
        'email',
        'password',
        'address',
        'phone_1',
        'phone_2',
      ]);
      Swal.fire('Error', 'Verifica la información ingresada', 'error');
      toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      return;
    }

    this.registerService
      .verifyEmail(email.value)
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            console.log('correo no encontrado');
            if (this.step < 8) {
              this.step++;
            }
          } else {
            console.error('Error en la consulta de cédula:', error);
            Swal.fire(
              'Error',
              'Ha ocurrido un error al consultar el email ' + email.value,
              'error'
            );
          }

          toggleLoader(this.loaderService, false); // Mostrar el componente de carga
          // Puedes retornar un valor predeterminado o un observable vacío si lo deseas
          return of(null);
        })
      )
      .subscribe((response: ResponseSearch | null) => {
        if (response && response.data) {
          console.log(response.data, 'resultado encontrado');
          // Aquí puedes manejar la respuesta cuando se encuentra la cédula
          Swal.fire(
            'Error',
            'El email ' + email.value + ' ya se encuentra registrado',
            'error'
          );
        }
        toggleLoader(this.loaderService, false); // Mostrar el componente de carga
      });
  }

  // marca todos los campos como tocados para validarlos
  markAllFieldsAsTouched() {
    Object.keys(this.allyForm.controls).forEach((controlName) => {
      this.allyForm.controls[controlName].markAsTouched();
    });
  }

  register() {
    // TODO: Implementar la lógica para procesar el formulario

    if (this.allyForm.valid) {
      const formData = new FormData();
      const formControlPhoto_document_front = this.allyForm.get(
        'photo_document_front'
      );
      const formControlPhoto_document_back = this.allyForm.get(
        'photo_document_back'
      );
      const formControlPhoto_selfie = this.allyForm.get('photo_selfie');
      if (formControlPhoto_document_front) {
        formData.append(
          'photo_document_front',
          formControlPhoto_document_front.value,
          formControlPhoto_document_front.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      if (formControlPhoto_document_back) {
        formData.append(
          'photo_document_back',
          formControlPhoto_document_back.value,
          formControlPhoto_document_back.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }

      if (formControlPhoto_selfie) {
        formData.append(
          'photo_selfie',
          formControlPhoto_selfie.value,
          formControlPhoto_selfie.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      formData.append('code_shop', this.allyForm.value.code_shop);
      formData.append('name', this.allyForm.value.name);
      formData.append('last_name', this.allyForm.value.last_name);
      formData.append('identification', this.allyForm.value.identification);
      formData.append('phone_1', this.allyForm.value.phone_1);
      formData.append('phone_2', this.allyForm.value.phone_2);
      formData.append('email', this.allyForm.value.email);
      formData.append('address', this.allyForm.value.address);
      formData.append('nit', this.allyForm.value.nit);
      formData.append(
        'registered_chamber_commerce',
        this.allyForm.value.registered_chamber_commerce
      );
      formData.append('business_name', this.allyForm.value.business_name);
      formData.append(
        'value_credit',
        this.allyForm.value.value_credit.replace(/,/g, '')
      );
      formData.append('city_id', this.allyForm.value.city_id);
      formData.append(
        'businness_address',
        this.allyForm.value.businness_address
      );
      formData.append('password', this.allyForm.value.password);
      formData.append('user_id_commerce', this.allyForm.value.user_id_commerce);
      formData.append(
        'identification_type',
        this.allyForm.value.identification_type
      );

      toggleLoader(this.loaderService, true); // Mostrar el componente de carga

      console.log(formData);
      this.registerService
        .saveRegister(formData)
        .pipe(
          catchError((err) => {
            toggleLoader(this.loaderService, false); // Ocultar el componente de carga

            console.log(err);
            if (err.status === 422) {
              Swal.fire(
                'Error',
                'Error de validación. Verifica los datos ingresados.',
                'error'
              );
            } else {
              if (err.error?.ok === false) {
                Swal.fire('Error', `${err.error?.message}`, 'error');
              } else {
                Swal.fire(
                  'Error',
                  'Ocurrió un error al guardar el registro. Inténtalo nuevamente.',
                  'error'
                );
              }
            }
            return throwError(err); // Return the error to continue error handling
          })
        )
        .subscribe((response: any) => {
          console.log(response);
          if (response?.ok) {
            Swal.fire({
              title: 'Éxito',
              text: 'Felicidades, te has registrado exitosamente. En un momento, serás redireccionado a tu cuenta',
              icon: 'success',
              timer: 2000, // La alerta se cerrará automáticamente después de 5 segundos
            });

            toggleLoader(this.loaderService, true);
            const email = this.allyForm.value.email;
            const password = this.allyForm.value.password;

            this.authService.login(email, password).subscribe(
              (response) => {
                toggleLoader(this.loaderService, false);
                console.log('AUTH LOGIN', response);
                if (response.ok) {
                  this.authService.setUser(response.data);
                  this.authService.setToken(response.token);
                  const rol = Number(response.data.role_id);
                  this.navService.updateMenu(rol);
                  // Redirigir a clientes/inicio
                  this.router.navigate(['clientes/inicio']);
                } else {
                  Swal.fire('Error', response.message, 'error');
                }
              },
              (error) => {
                toggleLoader(this.loaderService, false);
                Swal.fire('Error', error.error.message, 'error');
              }
            );
          } else {
            toggleLoader(this.loaderService, false); // Ocultar el componente de carga
            Swal.fire('Error', `${response?.message}`, 'error');
          }
        });
    } else {
      // Marcar todos los campos como tocados
      this.markAllFieldsAsTouched();
      Swal.fire('Error', 'Aun Faltan campos por diligenciar.', 'error');
    }
  }

  markFieldsSelectedAsTouched(fields: string[]) {
    fields.forEach((fieldName) => {
      const control = this.allyForm.get(fieldName);
      if (control) {
        control.markAsTouched();
      }
    });
  }
}
