import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/Loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
 public show: boolean = true;

  constructor(public loaderService: LoaderService) {
    setTimeout(() => {
      this.show = false;
    }, 3000);
  }

  ngOnInit() { }

  ngOnDestroy() { }


}
