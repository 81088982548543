import { Routes } from "@angular/router";

export const customerRouters: Routes = [
    
    /***********************
	 * RUTAS USUARIO CLIENTE*
	 **********************/
    {
		path: "clientes/inicio",
		loadChildren: () =>
            import(
                "../../customer/customer-dashboard/customers-dashboard.module"
            ).then((m) => m.CustomerDashboardModule),
	},
	{
		path: "clientes/solicitudes-credito",
		loadChildren: () =>
			import(
				"../../customer/customers-credits-requests/customers-credits-requests.module"
			).then((m) => m.CustomersCreditsRequestsModule),
	},

    {
		path: "clientes/creditos",
		loadChildren: () =>
			import(
				"../../customer/customers-credits/customers-credits.module"
			).then((m) => m.CustomersCreditsModule),
	},

    {
		path: "clientes/pagos",
		loadChildren: () =>
			import(
				"../../customer/customer-payments/customer-payments.module"
			).then((m) => m.CustomerPaymentsModule),
	},
];
