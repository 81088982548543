import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditsDocumentsResponseData } from 'src/app/administrative/credits-requests/interfaces/credits-documents-interface';
import { CreditHistoryInterface } from 'src/app/administrative/credits-requests/interfaces/credits-historyCreditsCurtomer-interface';
import { CreditsNotesResponseData } from 'src/app/administrative/credits-requests/interfaces/credits-notes-interface';
import { CreditRequestService } from 'src/app/administrative/credits-requests/services/credit-request.service';
import { CustomerInterface } from 'src/app/administrative/customers/interfaces/customer-interface';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-info-show-modal',
  templateUrl: './customer-info-show-modal.component.html',
  styleUrls: ['./customer-info-show-modal.component.css'],
})
export class CustomerInfoShowModalComponent {
  /**************************
   * Propiedades
   *********************/
  @Input() title: string = 'Solicitud de crédito';
  tabActiva = 'informacionPersonal';
  @Input() customerInfo: CustomerInterface; // Propiedad para almacenar la información del cliente
  @Input() creditRequestId: number; // Propiedad para almacenar el id de la solicitud de crédito
  datosDeshabilitados: boolean = true; // Inicialmente, los datos están deshabilitados
  customerForm: FormGroup; // Formulario reactivo
  notasAgregadas: CreditsNotesResponseData[] = [];
  documentsList: CreditsDocumentsResponseData[] = [];
  textareaContent: string; // Propiedad para almacenar el contenido del textarea
  historyCreditsCustomerList: CreditHistoryInterface[]; // Propiedad para almacenar el historial de créditos del cliente

  /**************************
   * constructor
   *********************/
  constructor(
    public activeModal: NgbActiveModal,
    private CreditRequestService: CreditRequestService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService
  ) {
    // Inicializar el formulario reactivo
    // this.customerForm = new FormGroup({
    //   id: new FormControl(this.customerInfo?.id),
    //   nit: new FormControl(this.customerInfo?.nit),
    //   name: new FormControl(this.customerInfo?.name),
    // });

    this.customerForm = this.formBuilder.group({
      id: [this.customerInfo?.id],
      nit: [this.customerInfo?.nit],
      name: [this.customerInfo?.name],
    });
  }

  /**************************
   * Inicio del ciclo de vida
   * ********************/
  ngOnInit(): void {
    toggleLoader(this.loaderService, true);
    this.getNotesCredits();
    this.getDocumentsCredits();
    this.getHistoryCredits();
  }

  /**************************
   * metodos
   *********************/

  cambiarPestana(pestana: string) {
    this.tabActiva = pestana;
  }

  subirDocumento(fileInput: any) {
    // Lógica para subir documentos
    const file = fileInput.files[0];

    if (!file) {
      Swal.fire('Error', 'No se seleccionó un archivo.', 'error');
      return;
    }
    toggleLoader(this.loaderService, true);
    const customerId = this.creditRequestId;
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('credit_request_id', customerId.toString());
    this.CreditRequestService.saveCustomerDocument(formdata).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('Response from server: ', response);
        if (response.ok) {
          this.getDocumentsCredits();
          Swal.fire('Éxito', 'Se agrego un nuevo documento.', 'success');
        } else {
          Swal.fire(
            'Error',
            'No se guardo el documento intenta nuevamente.',
            'error'
          );
        }
      }
    );
  }

  habilitarDatos() {
    this.datosDeshabilitados = false; // Habilitar los datos cuando se hace clic en el botón correspondiente
  }


  ActualizarDatos() {
    console.log('customerInfo', this.customerInfo);
    // const customerId = this.creditRequestId;
    // const nit = this.customerForm?.get('nit').value;
    // const name = this.customerForm?.get('name').value;
    // const data = {
    //   nit,
    //   name,
    // };
    // this.CreditRequestService.updateCustomer(customerId, data).subscribe(
    //   (response) => {
    //     console.log('Response from server: ', response);
    //     if (response.ok) {
    //       Swal.fire('Éxito', 'Se actualizo la información.', 'success');
    //     } else {
    //       Swal.fire(
    //         'Error',
    //         'No se actualizo la información intenta nuevamente.',
    //         'error'
    //       );
    //     }
    //   }

    // );
  }

  saveNotes() {
    const customerId = this.creditRequestId;
    const valueNote = this.textareaContent;
    //const note = this.customerForm?.get('note').value;
    console.log('Contenido del textarea:', this.textareaContent);
    toggleLoader(this.loaderService, true);
    this.CreditRequestService.saveCustomerNote(customerId, valueNote).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('Response from server: ', response);
        if (response.ok) {
          this.getNotesCredits();
          Swal.fire('Éxito', 'Se agrego una nueva nota.', 'success');
        } else {
            toggleLoader(this.loaderService, false);
            Swal.fire(
                'Error',
                'No se guardo la nota intenta nuevamente.',
                'error'
            );
        }
      }
    );
  }

  getNotesCredits() {
    toggleLoader(this.loaderService, true);
    const customerId = this.creditRequestId;
    this.CreditRequestService.getCustomerNotes(customerId).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('Response from server: ', response);
        if (response.ok) {
          this.notasAgregadas = response.data;
        } else {
          Swal.fire(
            'Error',
            'No se pudo obtener las notas. Inténtalo nuevamente.',
            'error'
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        Swal.fire(
          'Error',
          'Ocurrió un error al obtener las notas. Por favor, inténtalo nuevamente.',
          'error'
        );
        console.log('Error: ', error);
      }
    );
  }
  getDocumentsCredits() {
    toggleLoader(this.loaderService, true);
    const creditRequestId = this.creditRequestId;
    this.CreditRequestService.getCustomerDocuments(creditRequestId).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('Response from server: ', response);
        if (response.ok) {
          this.documentsList = response.data;
        } else {
          Swal.fire(
            'Error',
            'No se pudieron obtener los documentos. Inténtalo nuevamente.',
            'error'
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        Swal.fire(
          'Error',
          'Ocurrió un error al obtener los documentos. Por favor, inténtalo nuevamente.',
          'error'
        );
        console.log('Error: ', error);
      }
    );
  }
  getHistoryCredits() {
    const customerInfoId = this.customerInfo.id;
    toggleLoader(this.loaderService, true);
    this.CreditRequestService.getHistoryCreditsCustomers(
      customerInfoId
    ).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('Response from server: ', response);
        if (response.ok) {
          this.historyCreditsCustomerList = response.data;
        } else {
          Swal.fire(
            'Error',
            'No se pudieron obtener los créditos. Inténtalo nuevamente.',
            'error'
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        Swal.fire(
          'Error',
          'Ocurrió un error al obtener los créditos. Por favor, inténtalo nuevamente.',
          'error'
        );
        console.log('Error: ', error);
      }
    );
  }
}
