import {
	AbstractControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
} from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LoaderService } from "../services/Loader.service";
import * as XLSX from "xlsx";

export function numberDecimalValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const value = control.value;
		if (value === null || value === undefined || value === "") {
			return null; // Permitir campo vacío si es opcional
		}

		const isValid = /^\d+(\.\d{1,2})?$/.test(value);
		return isValid ? null : { numberDecimal: true };
	};
}

export function checkDateRangeValidator(
	startDateKey: string,
	endDateKey: string
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const startDate = control.get(startDateKey)?.value;
		const endDate = control.get(endDateKey)?.value;

		if (endDate && startDate && endDate < startDate) {
			return { dateRangeError: true };
		}

		return null;
	};
}

export function differentValuesValidator(control1Name: string, control2Name: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const control1 = control.get(control1Name);
      const control2 = control.get(control2Name);
  
      if (!control1 || !control2) {
        return null; // No validation if either control is not found
      }
  
      const control1Value = control1.value;
      const control2Value = control2.value;
  
      if (control1Value !== control2Value) {
        return null; // Values are different, no error
      }
  
      return { differentValues: true }; // Values are the same, return an error
    };
  }


  export function phone2Validator(control: AbstractControl): ValidationErrors | null {
    const phone2Value = control.value as string;
  
    if (phone2Value) {
      if (phone2Value.length < 7) {
        return { minlength: { requiredLength: 7, actualLength: phone2Value.length } };
      }
  
      if (phone2Value.length > 11) {
        return { maxlength: { requiredLength: 11, actualLength: phone2Value.length } };
      }
  
      if (!numericValidator()(control)) {
        return { invalidPhone2: true };
      }
    }
  
    return null;
  }
  
  

  // valida que los campos sean numericos
  export function numericValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const isNumeric =
				!isNaN(parseFloat(control.value)) && isFinite(control.value);
			return isNumeric ? null : { nonNumeric: { value: control.value } };
		};
  }
  
  

export function validateCampo(form: FormGroup, campo: string) {
	const control = form.controls[campo];
	if (control.errors && control.touched) {
		const errors: ValidationErrors | null = control.errors;
		if (errors && errors["required"]) {
			return "El campo es requerido";
		} else if (errors && errors["minlength"]) {
			return `El campo debe tener al menos ${errors["minlength"].requiredLength} caracteres`;
		} else if (errors && errors["maxlength"]) {
			return `El campo debe tener como máximo ${errors["maxlength"].requiredLength} caracteres`;
		} else if (errors && errors["numberDecimal"]) {
			return "El campo debe ser un número decimal válido";
		} else if (errors && errors["dateRangeError"]) {
			return "La fecha de inicio debe ser menor a la fecha de fin";
		} else if (errors && errors["differentValues"]) {
            return "Los valores no pueden ser iguales";
        } else if (errors && errors["email"]) {
            return "El campo debe ser un correo electrónico válido";
        } else if (errors && errors["nonNumeric"]) {
            return "El campo debe contener solo números";
          }
		return "Error en el campo";
	}

	return null;
}

export function formatDatePickerField(field: any): string {
	const date: NgbDateStruct = field.value;
	if (date) {
		const formattedDate = `${date.year}-${date.month
			.toString()
			.padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;
		return formattedDate;
	}
	return "";
}

export function convertToNgbDate(date: string): NgbDateStruct {
	// Divide la fecha en sus componentes (año, mes, día)
	const [year, month, day] = date.split("-");

	// Crea una instancia de NgbDateStruct con los componentes de fecha
	const ngbDate: NgbDateStruct = { year: +year, month: +month, day: +day };

	return ngbDate;
}

export function getStateRequestDescription(stateId: number) {
	switch (stateId) {
		case 0 | 1:
			return "Solicitud";
		case 2:
			return "Estudio";
		case 3:
			return "Aprobado";
		case 4:
			return "Rechazado";
		case 5 | 7:
			return "Aprobado con desembolso";
		case 6:
			return "Anulado";
		default :
			return "---";
	}
}

export function getStateRequestColor(stateId: number) {
	switch (stateId) {
		case 0 | 1:
			return "warning";
		case 2:
			return "info";
		case 3:
			return "success";
		case 4:
			return "danger";
		case 5 | 7:
			return "primary";
		case 6:
			return "danger";
		default:
			return "danger";
	}
}


export function getStateCodeCustomer(code:number) {
    switch (code) {
        case 1:
            return "validado";
        case 2:
            return "Código expirado";
        case 0:
        default:
            return "Sin validar";
    }

}

export function getStateCodeColorCustomer(code:number) {
    switch (code) {
        case 1:
            return "success";
        case 2:
            return "warning";
        case 0:
            return "danger";
        default:
            return "danger";
    }
}

export function toggleLoader(loaderService: LoaderService, value: boolean) {
	loaderService.isLoading$.next(value);
}

export function exportToExcel(
    data: any[],
    fileName: string,
    sheetName: string,
    columns: { header: string; key: string }[]
) {
    const getValueByPath = (obj:any, path:any) => {
        return path.split('.').reduce((accum:any, key:any) => (accum ? accum[key] : null), obj);
    };

    const formatDate = (dateString:any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const filteredData = data.map((item) => {
        const filteredItem: any[] = [];
        columns.forEach((column) => {
            let value = getValueByPath(item, column.key);
            if (column.key === 'created') {
                value = formatDate(value);
            }
            filteredItem.push(value);
        });
        return filteredItem;
    });

    const worksheetData = [columns.map((column) => column.header), ...filteredData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    worksheet["!cols"] = columns.map((column, index) => ({
        wch: column.header.length,
    }));
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
}

export function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}


export function formatCurrency(value: number | null | undefined, showSymbol: boolean = true): string {
    if (value === null || value === undefined) {
        return '';
    }

    const formattedValue = value.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true,
    }).replace(/\./g, ',');

    return showSymbol ? `$${formattedValue}` : formattedValue;
}


export function formatCurrencyInput(form: FormGroup, fieldName: string, event: Event, showSymbol: boolean): void {
    const inputElement = event.target as HTMLInputElement;
    const numericValue = inputElement.value.replace(/[^0-9]/g, '');
    const formattedValue = formatCurrency(Number(numericValue), showSymbol);

    // Actualizar el campo especificado en el formulario
    form.patchValue({ [fieldName]: formattedValue });
}


export function getNumericOnly(value: string): number {
    const numericValue = value.replace(/\D/g, '');
    return Number(numericValue);
}


  



  


  
  

