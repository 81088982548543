import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/login/services/auth.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-account",
	templateUrl: "./account.component.html",
	styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
    
    //variables
	public userName: string;
	public profileImg: "assets/images/dashboard/profile.jpg";

    //constructor
	constructor(
		public router: Router,
		private authService: AuthService,
		private loaderService: LoaderService
	) {}

    //ngOnInit
	ngOnInit(): void {}

    //funciones
	logoutFunc() {
		toggleLoader(this.loaderService, true);
        localStorage.removeItem("user");
		localStorage.removeItem("token");
		this.authService.logout().subscribe(
			(response) => {
				toggleLoader(this.loaderService, false);
                this.router.navigate(["/auth/login"]);
			},
			(error) => {
				toggleLoader(this.loaderService, false);
				// Swal.fire("Error", error.error.message, "error");
                this.router.navigate(["/auth/login"]);
			}
		);
	}
}
