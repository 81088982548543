import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditsService } from 'src/app/administrative/credits/services/credits.service';
import { toggleLoader, validateCampo } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credits-requests-modal-edit-value-credit',
  templateUrl: './credits-requests-modal-edit-value-credit.component.html',
  styleUrls: ['./credits-requests-modal-edit-value-credit.component.css'],
})
export class CreditsRequestsEditValueComponent {
    creditRequestValue: number =0; // Propiedad para almacenar el valor de la solicitud de crédito
    creditRequestnObligacion: number=0; // Propiedad para almacenar el valor de la solicitud de crédito
    creditState:number;
    creditId : number;
    dateCredit: string; // Propiedad para almacenar el contenido de Selecciona un motivo
	formEditCredit: FormGroup;
    validateCampo = validateCampo; // Función para validar campos

  constructor(
    public activeModal: NgbActiveModal,
    private loaderService: LoaderService,
    private creditsService: CreditsService,
    private formBuilder: FormBuilder,
    ) {}


    //ngOnInit
	ngOnInit(): void {
		this.initForm();
	}


    //inicializar formulario
    initForm(): void {
		this.formEditCredit = this.formBuilder.group({
			value_credit: [this.creditRequestValue, Validators.required],
			date_credit: [this.dateCredit, Validators.required],
			reason_edit: [""],
		});
	}


    //actualizar credito
  updateCredit() {
    if (this.formEditCredit.invalid) {
        this.formEditCredit.markAllAsTouched();
        Swal.fire("Error", "Debe completar todos los campos", "error");
        return; // Salir de la función si faltan campos por llenar
    }

    const { reason_edit } = this.formEditCredit.value;
    if (this.creditState!==2 && reason_edit === "" || 
    this.creditState!==2 && reason_edit === null) {
        Swal.fire("Error", "Debe completar la razón de la edición", "error");
        return; // Salir de la función si faltan campos por llenar
    }

    //loader
    toggleLoader(this.loaderService, true); 
    //Llamar al servicio 
    this.creditsService
    .updateCredit(this.creditId, this.formEditCredit.getRawValue())
    .subscribe(
        (response) => {
            toggleLoader(this.loaderService, false); 
            if (response.ok) {
              
                Swal.fire('Éxito', `El crédito  ${this.creditRequestnObligacion}, ha sido actualizado correctamente`, 'success');
            } else {
                Swal.fire("Error", response.message, "error");
            }
        },
        (error) => {
            toggleLoader(this.loaderService, false); 
            Swal.fire(
                "Error",
                error.error.message,
                "error"
            );
        }
    );
}

}
