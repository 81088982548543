<div class="header-wrapper row m-0">
  <div class="header-logo-wrapper col-auto p-0">
    <div class="logo-wrapper">
      <a routerLink="/"
        ><img
          class="img-fluid image-dark"
          src="assets/images/logo/logo.png"
          alt=""
      /></a>
      <a routerLink="/"
        ><img
          class="img-fluid for-dark image-light"
          src="assets/images/logo/logo_dark.png"
          alt=""
      /></a>
    </div>
    <div class="toggle-sidebar" (click)="sidebarToggle()">
      <div class="status_toggle sidebar-toggle d-flex">
        <svg-icon src="assets/svgIcon/menu.svg"></svg-icon>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="role !== 5" class="left-side-header col ps-0 d-none d-md-block"> -->
  <div class="left-side-header col ps-0  d-md-block">
    <div *ngIf="role == 1 || role == 4 || role == 6">
      <app-search></app-search>
    </div>
  </div>

  <div class="nav-right col-10 col-sm-6 pull-right right-header p-0">
    <ul class="nav-menus">
      <!-- <li class="mode" (click)="layoutToggle()">
        <app-mode></app-mode>
      </li> -->

      <li class="maximize">
        <app-maximize></app-maximize>
      </li>
      <li class="profile-nav onhover-dropdown pe-0 py-0 me-0">
        <app-account></app-account>
      </li>
    </ul>
  </div>
</div>
