import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreditLineDataPaginated, CreditLineInterface, CreditLinePaginatedInterface, CreditLineResponseInterface } from '../interfaces/credits-lines-interface';

@Injectable({
  providedIn: 'root'
})
export class CreditLineService {

  /*************
  *  variables**
  *************/
  private baseUrl: string =environment.apiUrl+'/lineas-credito';

  public creditslinesSelected : CreditLineInterface;;
  public creditslinesAction : string = 'Crear';
  //refrescar tabla al guardar o actuailzar
  private _refreshCreditsLines$ = new Subject<void>();

  //constructor
  constructor(private http: HttpClient) { }

  /*************
  *  getters****
  *************/
  get _refreshCreditsLines() {
    return this._refreshCreditsLines$;
  }

  /*************
  *  metodos****
  *************/

  //obtener registros
  getCreditsLines(page:number) {
    const url = page==0 ?  `${this.baseUrl}` : `${this.baseUrl}/page/${page}`;
    return this.http.get<CreditLinePaginatedInterface>(url);
  }


  //guardar registro
  storeCreditLine(creditslines: CreditLineInterface) {
    return this.http.post<CreditLineResponseInterface>(this.baseUrl, creditslines)
      .pipe(
        tap(() => {
          this._refreshCreditsLines$.next();
        })
      );
  }

  //traer información del registro
  showCreditLine(id: number) {
    return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/${id}`);
  }

  //actualizar registro
  updateCreditLine(id:number,creditslines: CreditLineInterface): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, creditslines).pipe(
      tap(() => {
        this._refreshCreditsLines$.next();
      })
    );
  }

  //actualizar registro
  deleteCreditLine(id:number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`).pipe(
      tap(() => {
        this._refreshCreditsLines$.next();
      })
    );
  }


  getCreditsLinesByShop(shopCommerceId:number) {
    const url=`${this.baseUrl}/asignadas/proveedor/sucursal/${shopCommerceId}`;
    return this.http.get<CreditLineResponseInterface>(url);
  }


  //habilitar registro
  enableCreditLine(id:number): Observable<any> {
    return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/activar/${id}`).pipe(
      tap(() => {
        this._refreshCreditsLines$.next();
      })
    );
  }

  
  //desabilitar registro
  disableCreditLine(id:number): Observable<any> {
    return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/desactivar/${id}`).pipe(
      tap(() => {
        this._refreshCreditsLines$.next();
      })
    );
  }

}
